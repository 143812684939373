import { ChangeDetectionStrategy, Component, inject, signal } from "@angular/core";
import { FeatureSettingsComponent } from "./feature.settings.component";
import { FeaturesService } from "./features.service";
import { SecurityService } from "@fry/core/security.service";
import { ResponseWrapperComponent } from "@fry/core/response.wrapper";

@Component({
    selector: "eas-protected-settings",
    templateUrl: './protected.settings.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FeatureSettingsComponent,
        ResponseWrapperComponent,
    ]
})
export class ProtectedSettingsComponent {

  private security = inject(SecurityService);
  private featureService = inject(FeaturesService);
  public featureResponse = this.featureService.featureResponse;
  public features = this.featureService.protectedFeatures;
  public isSuperAdmin = signal<boolean>(false);

  async ngOnInit() {
    this.security.hasRole('system:superadmin').then(hasRole => this.isSuperAdmin.set(hasRole));
    await this.featureService.fetchFeatures();
  }

}