import Swal from "sweetalert2";
import { Component, computed, inject } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MFA, MFAMethod, READABLE_MFA_METHODS } from "@fry/system/account/mfa/mfa";
import { MFAService } from "@fry/system/account/mfa/mfa.service";
import { MatDialog } from "@angular/material/dialog";
import { CreateMfaDialogComponent } from "@fry/system/account/mfa/create-mfa-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RouteService, upgradeProviders } from '@fry/upgrade/route.service';
import { setPageTitle } from '@fry/core/utils/seo';
import { OrganisationService } from "@fry/core/organisation.service";

@Component({
    selector: 'eas-mfas',
    templateUrl: './list-mfas.component.html',
    imports: [CommonModule],
    providers: [
        ...upgradeProviders
    ]
})
export class ListMfasComponent {
  private routeService = inject(RouteService);
  private credentialUsername = this.routeService.getParam('credentialUsername');
  private _remoteUser = this.routeService.getParam('remoteUser');
  public remoteUser = computed(() => this._remoteUser());
  public isForRemoteUser = computed(() => this.remoteUser() !== undefined);

  mfas: MFA[] = [];
  readableName = READABLE_MFA_METHODS
  availableMethods: MFAMethod[] = [];

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private organisationService: OrganisationService,
    private MFAService: MFAService,
  ) {}

  ngOnInit() {
    setPageTitle('Two Factor Authentication');
    this.loadUserRegisteredMFAsAndAvailableMethods();
  }

  private async loadUserRegisteredMFAsAndAvailableMethods() {
    const response = await this.MFAService.getRegisteredMFAs(this.credentialUsername(), this.remoteUser());
    if (response.state !== "ok") {
      return;
    }

    this.mfas = response.data;
    const organisation = this.organisationService.currentOrganisation();
    const registeredMethods = this.mfas.map(mfa => mfa.type);
    this.availableMethods = organisation.enabledMFAs.filter(method => !registeredMethods.includes(method.id));
  }

  canAdd(): boolean {
    return this.availableMethods.length > 0 && !this.isForRemoteUser();
  }

  canDelete(): boolean {
    if (this.isForRemoteUser()) {
      return true;
    }
    return this.mfas.length !== 1
  }

  createMFA() {
    const dialogRef = this.dialog.open(CreateMfaDialogComponent, {
      minWidth: '30vw',
      disableClose: true,
      data: {
        credentialUsername: this.credentialUsername(),
        availableMethods: this.availableMethods
      },
    });

    dialogRef.componentInstance.completed.subscribe((result: any) => {
      dialogRef.close();
      if (!result.method) {
        return;
      }

      this.snackBar.open(`You've successfully setup two factor authentication!`, 'Close', { duration: 3000 });
      this.loadUserRegisteredMFAsAndAvailableMethods();
    });
  }

  async deleteMFA(mfaId: string) {
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this two factor authentication method? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    });

    if (!confirmation.isConfirmed) {
      return;
    }

    const response = await this.MFAService.deleteMFA(this.credentialUsername(), mfaId, this.remoteUser());
    if (response.state !== "ok") {
      this.snackBar.open(`Failed: ${response.error}`, 'Close', { duration: 3000 });
      return;
    }
    this.snackBar.open(`You've successfully deleted the two factor method!`, 'Close', { duration: 3000 });
    this.loadUserRegisteredMFAsAndAvailableMethods();
  }
}
