import apiCheck from 'api-check';
import $ from 'jquery';
import 'arrive';
import 'bootstrap-material-design';
import 'bootstrap-datepicker';
import { templates } from './tplCache';
import { VERSION } from './current_version';
import { downgradeInjectable } from '@angular/upgrade/static';
import { BroadcastService } from 'src/ang2/app/core/broadcast.service';
import { FeaturesService } from '@fry/labs/features.service';
import { OrganisationService } from '@fry/core/organisation.service';

(function() {
  'use strict';

  function config($locationProvider, pouchDBProvider, POUCHDB_METHODS) {
    $.material.init();

    var authMethods = {
      validatingPut: 'qify'
    };
    pouchDBProvider.methods = angular.extend([], POUCHDB_METHODS, authMethods);
    $locationProvider.hashPrefix('');
  }

  function appRun($log, $rootScope, $state, $location, $uibModalStack, loadingBar,
    kzLocalStorage, Network, CheckService, Profile, Events, formlyConfig, FORM_TYPES,
    FORM_WRAPPERS, _ShareService, Notify, OidcService, Auth, FrylyticsService,
    SERVER_CONFIG, $transitions, $templateCache, BroadcastService, KZ_ENVIRONMENT) {
    $log.debug('Starting app');

    _.forOwn(templates, function(val, key) {
      $templateCache.put(key, val);
    });

    BroadcastService.messages$.subscribe(message => {
      $rootScope.$broadcast(message.msg, message.data);
    });

    // detect network connectivity
    CheckService.detectConnectivity();

    // This has been moved to index.html
    // if (!Network.isOffline()) {
    //   OidcService.isLoggedIn()
    //     .then(function(loggedIn) {
    //       if (!loggedIn) {
    //         OidcService.login(
    //          { organisation: Auth.currentOrganisation(), url: $location.url() });
    //       }
    //     });
    // }

    // set up the various form elements needed by formly
    formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted';

    apiCheck.globalConfig.disabled = true;
    formlyConfig.disableWarnings = true;
    if (KZ_ENVIRONMENT !== 'local') {
      if (window.apiCheck !== undefined) {
        window.apiCheck.globalConfig.disabled = true;
      }

      formlyConfig.disableWarnings = true;
    }

    _.forEach(FORM_WRAPPERS, function(wrapper) {
      formlyConfig.setWrapper(wrapper);
    });
    _.forEach(FORM_TYPES, function(type) {
      formlyConfig.setType(type);
    });

    // If we have kz came from, redirect there
    var cameFrom = kzLocalStorage.popItem('kz-cameFrom');
    if (cameFrom.url) {
      // $window.location.href = cameFrom.url;
      $location.url(cameFrom.url);
    }

    // Handle sidebar cards
    // SidebarService.registerAll();
    var ignoredStates = [
      'accounts.login',
      'accounts.register',
      'epf.events.new',
      'epf.events.section-new',
      'epf.events.section-fillin'];


    var handleError;
    $transitions.onStart({ }, function(trans) {
      $rootScope.$broadcast('KZCurrentListChange', {});
      // Dismiss all opened bootstrap modals
      $uibModalStack.dismissAll();
      loadingBar.start();
      var Users = trans.injector().get('UsersService');
      var toParams = trans.params();
      var fromState = trans.from();
      var fromParams = trans.params('from');
      Users.remoteUser = toParams.user;

      if (ignoredStates.indexOf(fromState.name) === -1) {
        $rootScope.previousState = fromState;
        $rootScope.previousStateParams = fromParams;
      }
      loadingBar.complete();
    });

    $transitions.onError({ }, function(trans) {
      loadingBar.complete();
      return handleError(trans, trans.error());
    });

    $transitions.onStart({ to: 'epf.**' }, function(trans) {
      if ($rootScope.needRelogin) {
        $rootScope.needRelogin = false;
        trans.router.stateService.target('accounts.login');
      }
    });

    $rootScope.$on('KZCurrentListChange', function(_evt, args) {
      $log.debug('List changed to:', args.list ? args.list : 'none');
      Events.currentList = args.list;
      Events.currentSearch = args.search;
    });

    handleError = function(trans, err) {
      // Aborted
      if (err === undefined) {
        console.log('Undefined error');
        return;
      }

      if (err.type === 3 || err.type === 5) {
        return;
      }
      var error = err.detail;

      if (error === null || error.status === undefined) {
        return;
      }

      var go;
      var currUrl = $location.url();
      var registerToken = $location.search().token;
      var search = { came_from: currUrl };

      var to = trans.to();
      var toState = to.name;
      var toParams = to.params;
      var fromState = trans.from().name;

      if (error.status === 302) {
        if (error.url) {
          $location.url(error.url);
          // event.preventDefault();
        } else {
          $state.go(error.state, error.stateParams)
            .then(function() {
              _.forOwn(error.location || {}, function(value, key) {
                $location.search(key, value);
              });
            });
        }
        return;
      } else if (error.status === 401) {
        $rootScope.needRelogin = false;
        if (registerToken) {
          go = $state.go('accounts.register');
          search.token = registerToken;
        } else if (error.code === 'step-up') {
          Notify.error('Step up authentication required');
          return;
        } else if (Network.isOffline()) {
          go = $state.go('accounts.pin');
        } else {
          if (SERVER_CONFIG.authType === 'oidc') {
            OidcService.login({ organisation: Auth.currentOrganisation(), lastUrl: currUrl });
            return;
          }
          go = $state.go('accounts.login');
        }
      } else if (error.status === 411) {
        $state.go(
          'errors.differentUser',
          { error: error, toState: toState, toParams: toParams },
          { location: false }
        );
        return;
      } else if (error.status === 477) {
        go = $state.go('accounts.org-chooser');
      } else if (error.status === 478) {
        go = $state.go('accounts.org-chooser');
      } else if (error.status === 488) {
        go = $state.go('accounts.initial-replication', {}, { reload: true });
      } else if (error.status === 489) {
        go = $state.go('accounts.initial-replication', {}, { reload: true });
      } else if (error.status === 467) {
        go = $state.go('accounts.auto-register', {}, { reload: true });
      } else if (error.status === 479) {
        console.log('User has no organisation');
        go = $state.go('accounts.login');
      } else if (error.status === 509) {
        if (!_.isEmpty(fromState.name)) {
          Notify.error(error.message || 'You appear to be offline');
          return;
        }
      } else if (error.status === 510) {
        Notify.warning(error.message || 'You appear to be offline');
        // Retry
        go = $state.go(toState, toParams);
      }

      // todo: what about 490?

      if (go !== undefined) {
        go.then(function() {
          if (currUrl !== '/') {
            $location.search(search);
          }
        });
        // event.preventDefault();
        return;
      }

      var hasChangeLocation = (error && error.changeLocation !== undefined);
      var changeLocation = hasChangeLocation ? error.changeLocation : false;
      $state.go(
        'errors.error',
        { error: error, toState: toState, toParams: toParams },
        { location: changeLocation }
      );
      return;
    };

    $rootScope.$on('KZPreferencesChange', function() {
      Profile.savePreferences();
    });
    $log.debug('Finished setup app');
    $log.debug('Frylytics INIT');
    FrylyticsService.init();
    $log.debug('/Frylytics INIT');
  }

  config.$inject = ['$locationProvider', 'pouchDBProvider', 'POUCHDB_METHODS'];
  appRun.$inject = [
    '$log', '$rootScope', '$state', '$location', '$uibModalStack', 'cfpLoadingBar',
    'kzLocalStorage', 'NetworkService', 'CheckService',
    'ProfileService', 'EventsService', 'formlyConfig', 'FORM_TYPES', 'FORM_WRAPPERS',
    'ShareService', 'NotifyService', 'OidcService', 'AuthService',
    'FrylyticsService', 'SERVER_CONFIG',
    '$transitions', '$templateCache', 'BroadcastService', 'KZ_ENVIRONMENT'];

  angular.module('epf',
    [

      // Core
      'epf.core',

      // Layout
      'epf.layout',

      // Blocks
      'epf.blocks',

      // Components
      'epf.component'

    ]
  );

  angular.module('epf.core')
    .constant('KZ_ENVIRONMENT', 'local')
    .constant('KZ_CONFIG', {})
    .constant('PACKAGE_VERSIONS', VERSION);

  angular.module('epf')
    .config(config)
    .service('BroadcastService', downgradeInjectable(BroadcastService))
    .service('FeaturesService', downgradeInjectable(FeaturesService))
    .service('OrganisationService', downgradeInjectable(OrganisationService))
    .run(appRun);

  window.runKaizen = function() {
    angular.bootstrap(document, ['epf'], { strictDi: true });
  };
})();

export default 'epf';
