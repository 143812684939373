import { Injectable, inject } from '@angular/core';
import { ClusterService } from '../core/cluster.service';
import { OidcService } from './oidc.service';

export interface Response<T> {
    state: 'error' | 'ok' | 'empty' | 'loading';
    error?: any;
    data: T;
}

@Injectable({
    providedIn: 'root'
})
export class Api {


    private oidc = inject(OidcService);
    private clusterService = inject(ClusterService);

    async get<T>(path: string) {
        return this.call<T>('GET', path);
    }

    async post<T>(path: string, body?: any) {
        return this.call<T>('POST', path, body);
    }

    async put<T>(path: string, body?: any) {
        return this.call<T>('PUT', path, body);
    }

    async delete<T>(path: string) {
        return this.call<T>('DELETE', path);
    }

    private async _call<T>(method: string, path: string, body?: any, retried?: boolean): Promise<T> {
        const apiInfo = this.oidc.apiInfo();

        const urls = this.clusterService.getCurrentUrls();
        const response = await fetch(urls.api + path, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': apiInfo.token,
                'EAS-Organisation': apiInfo.organisation || '',
                'EAS-Username': apiInfo.username || '',
            },
        });

        const data = await response.json();
        if (response.status === 401) {
            if (retried) {
                throw new Error('Unauthorized');
            }

            if (data.message && data.message.includes('stepup')) {
                await this.oidc.stepUp();
            } else {
                await this.oidc.renewToken();
            }
            return this._call<T>(method, path, body, true);
        }

        if (!response.ok) {
            throw new Error(data.message || 'Unknown error');
        }
        return data;
    }

    async call<T>(method: string, path: string, body?: any): Promise<Response<T>> {
        try {
            const data = await this._call<T>(method, path, body);
            return {
                state: 'ok',
                data: data
            }
        } catch (error) {
            return {
                state: 'error',
                error: error,
                data: null
            }
        }
    }
}
