import { Component, inject } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-stepup',
    templateUrl: './stepup.component.html',
    imports: [
        MatDialogModule,
        MatButton
    ],
})
export class StepupComponent {

    private dialogRef = inject(MatDialogRef);

    async onSubmit() {
        this.dialogRef.close(true);
    }

    async onCancel() {
        this.dialogRef.close(false);
    }

}
