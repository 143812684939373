export const seoTags = {
  pageTitleDefault: 'risr/advance',
  pageTitlePrefix: 'risr/advance - '
};

// See UtilsService in the old Angular app
export function setPageTitle(title: string) {
  if (typeof title === 'string') {
    document.title = `${seoTags.pageTitlePrefix} ${title}`;
  } else {
    console.warn(`The page title should be a string. You passed ${typeof title}: ${JSON.stringify(title)}`);
    document.title = `${seoTags.pageTitleDefault}`;
  }
};
