import { downgradeComponent } from '@angular/upgrade/static';
import {
  ListCredentialsComponent
} from '@fry/system/account/credential/list-credentials.component';
import { ListMfasComponent } from '@fry/system/account/mfa/list-mfas.component';

(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider

      .state('dashboard.profile', {
        url: 'profile',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }]
        }
      })

      .state('dashboard.profile.viewown', {
        url: '/view/',
        views: {
          content: {
            templateUrl: 'app/components/users/view.html',
            controller: 'UserViewController',
            controllerAs: 'userViewCtrl'
          }
        }
      })

      .state('dashboard.profile.view', {
        url: '/view/:user/',
        views: {
          content: {
            templateUrl: 'app/components/users/view.html',
            controller: 'UserViewController',
            controllerAs: 'userViewCtrl'
          }
        }
      })

      .state('dashboard.profile.edit', {
        url: '/edit/:user/:goTo',
        views: {
          content: {
            templateUrl: 'app/components/users/edit.html',
            controller: 'UserEditController',
            controllerAs: 'userEditCtrl'
          }
        },
        params: {
          user: '',
          goTo: ''
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          auth: ['setup', '$stateParams', 'SecurityService', 'AuthService',
            function(_setup, $stateParams, Security, Auth) {
              var username = $stateParams.user;
              var permission = 'users.edit';
              var check;
              if (_.isEmpty(username) || username === Auth.currentUser()) {
                check = Security.hasPermission(permission + '.own');
              } else {
                check = Security.hasPermissionFor(permission, username);
              }

              return check;
            }
          ]
        }
      })

      .state('dashboard.profile.security', {
        url: '/security/credentials',
        views: {
          content: {
            component: 'easCredentials'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          stepUp: ['OidcService', function(Oidc) {
            return Oidc.ensureStepup();
          }]
        }
      })

      .state('dashboard.profile.mfas', {
        url: '/security/credentials/:credentialUsername/mfas',
        views: {
          content: {
            component: 'easMFAs'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          stepUp: ['OidcService', function(Oidc) {
            return Oidc.ensureStepup();
          }]
        }
      })

      .state('epf.users', {
        url: 'users/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }]
        }
      })

      .state('epf.users.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/users/list.html',
            controller: 'UsersController',
            controllerAs: 'usersCtrl'
          }
        },
        data: {
          action: 'view'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('users.view');
          }]
        }
      })

      .state('epf.users.new', {
        url: 'new',
        views: {
          content: {
            templateUrl: 'app/components/users/create.html',
            controller: 'UserCreateController',
            controllerAs: 'userCreateCtrl'
          }
        },
        data: {
          action: 'new'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('users.create');
          }]
        }
      })

      .state('epf.users.security', {
        url: 'security/:remoteUser/credentials',
        views: {
          content: {
            component: 'easCredentials'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          auth: ['$stateParams', 'SecurityService',
            function($stateParams, Security) {
              return Security.hasPermissionFor('credential.manage', $stateParams.remoteUser);
            }
          ],
          stepUp: ['OidcService', function(Oidc) {
            return Oidc.ensureStepup();
          }]
        }
      })

      .state('epf.users.mfas', {
        url: 'security/:remoteUser/credentials/:credentialUsername/mfas',
        views: {
          content: {
            component: 'easMFAs'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          auth: ['setup', '$stateParams', 'SecurityService',
            function(_setup, $stateParams, Security) {
              return Security.hasPermissionFor('credential.manage', $stateParams.remoteUser);
            }
          ],
          stepUp: ['OidcService', function(Oidc) {
            return Oidc.ensureStepup();
          }]
        }
      })

      .state('epf.users.review', {
        url: 'review/:user',
        views: {
          content: {
            templateUrl: 'app/components/users/review.html'
            // controller: 'UserReviewController',
            // controllerAs: 'userReviewCtrl'
          }
        },
        resolve: {
          redirect: ['$q', function($q) {
            return $q.reject({
              status: 302,
              state: 'epf.admin.pending-sections'
            });
          }]
        }
      })

      .state('epf.users.events', {
        url: 'edit/:user/events/list/:filterId',
        views: {
          content: {
            templateUrl: 'app/components/users/eventlist.html',
            controller: 'UserEventsController',
            controllerAs: 'eventsCtrl'
          }
        },
        params: {
          filterId: ''
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          resolvedFilter: ['setup', '$state', '$stateParams', 'OrganisationsService',
            function(_setup, _$state, $stateParams, Organisations) {
              if (_.isEmpty($stateParams.filterId)) {
                return;
              }

              return Organisations.getFilter('epf.events.index', $stateParams.filterId)
                .then(function(data) {
                  return data;
                });
            }
          ],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('events.view');
          }]
        }
      })

      .state('epf.users.files', {
        url: 'edit/:user/files',
        views: {
          content: {
            templateUrl: 'app/components/files/files.html',
            controller: 'FilesController',
            controllerAs: 'filesCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('events.view');
          }]
        }
      })

      // .state('epf.users.reports', {
      //   url: 'edit/:user/reports',
      //   views: {
      //     content: {
      //       templateUrl: 'app/components/users/reportlist.html',
      //       controller: 'UserReportsController',
      //       controllerAs: 'reportsCtrl'
      //     }
      //   }
      //   // todo: Security check in controller. Should we move that here instead?
      // })

      .state('epf.users.goals', {
        url: 'edit/:user/goals/:filterId',
        views: {
          content: {
            templateUrl: 'app/components/users/goalslist.html',
            controller: 'UserGoalsController',
            controllerAs: 'goalsCtrl'
          }
        },
        params: {
          filterId: ''
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('goals.view');
          }],
          resolvedFilter: ['setup', '$stateParams', 'OrganisationsService',
            function(_setup, $stateParams, Organisations) {
              if (_.isEmpty($stateParams.filterId)) {
                return;
              }

              return Organisations.getFilter('epf.goals.index', $stateParams.filterId)
                .then(function(data) {
                  return data;
                });
            }]
        }
      })

      .state('epf.users.goals-add', {
        url: 'add/:user/goals/:eventId/:sectionId/:fieldId',
        views: {
          content: {
            templateUrl: 'app/components/goals/goals-definition.html',
            controller: 'GoalsDefinitionController',
            controllerAs: 'GoalsDefinitionCtrl'
          }
        },
        data: {
          action: 'add'
        }
      })

      .state('epf.users.goals-editDefinition', {
        url: 'edit-definition/:user/goals/:id',
        views: {
          content: {
            templateUrl: 'app/components/goals/goals-definition.html',
            controller: 'GoalsDefinitionController',
            controllerAs: 'GoalsDefinitionCtrl'
          }
        },
        data: {
          action: 'edit'
        }
      })

      .state('epf.users.goals-view', {
        url: 'view/:user/goals/:id',
        views: {
          content: {
            templateUrl: 'app/components/goals/goal.html',
            controller: 'GoalController',
            controllerAs: 'goalCtrl'
          }
        },
        data: {
          action: 'view'
        }
      })

      .state('epf.users.goals-work', {
        url: 'work/:user/goals/:id',
        views: {
          content: {
            templateUrl: 'app/components/goals/goal.html',
            controller: 'GoalController',
            controllerAs: 'goalCtrl'
          }
        },
        data: {
          action: 'work'
        }
      })

      .state('epf.users.goals-link', {
        url: 'work/:user/targets/link/:goalId/:targetId',
        views: {
          content: {
            templateUrl: 'app/components/goals/targets.link.html',
            controller: 'TargetsLinkController',
            controllerAs: 'targetsLinkController'
          }
        },
        data: {
          action: 'work'
        }
      })

      .state('epf.users.section-new', {
        url: 'edit/:user/events/new',
        views: {
          content: {
            templateUrl: 'app/components/events/eventsection.edit.html',
            controller: 'SectionController',
            controllerAs: 'eventCtrl'
          }
        },
        data: {
          action: 'new'
        },
        resolve: {
          resolvedEventSection: [function() {}],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('events.create');
          }]
        },
        params: {
          defaults: null
        }
      })

      .state('epf.users.events-view', {
        url: 'edit/:user/events/:id/:scrollTo',
        views: {
          content: {
            templateUrl: 'app/components/events/view.html',
            controller: 'EventViewController',
            controllerAs: 'eventViewCtrl'
          }
        },
        params: {
          scrollTo: ''
        },
        data: {
          action: 'view'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          resolvedEvent: [
            'setup', 'EventFactory', '$stateParams',
            function(_db, Event, $stateParams) {
              var event = new Event({});
              return event.load($stateParams.id, $stateParams.user);
            }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('events.view');
          }]
        }
      })

      .state('epf.users.events-view-any', {
        url: 'edit/events/:id',
        resolve: {
          resolvedEvent: [
            'setup', '$q', 'EventsService', '$stateParams',
            function(_db, $q, Events, $stateParams) {
              return Events.findFor($stateParams.id)
                .then(function(event) {
                  return $q.reject(
                    {
                      status: 302,
                      state: 'epf.users.events-view',
                      stateParams: { user: event.doc.user, id: event.doc._id }
                    });
                });
            }]
        }
      })

      .state('epf.users.sections-view', {
        url: 'edit/:user/sections/:id/:scrollTo',
        views: {
          content: {
            templateUrl: 'app/components/events/eventsection.view.html',
            controller: 'EventSectionViewController',
            controllerAs: 'eventViewCtrl'
          }
        },
        params: {
          scrollTo: ''
        },
        data: {
          action: 'view'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          resolvedEventSection: [
            'setup', 'EventSectionsService', 'EventSectionFactory', '$stateParams',
            function(_db, EventSectionsService, EventSection, $stateParams) {
              return EventSectionsService.findFor($stateParams.id, $stateParams.user)
                .then(function(item) {
                  return new EventSection(item.doc, { isShared: true, isLocal: false });
                });
            }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('events.view');
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.users')
    .directive('easCredentials', downgradeComponent({
      component: ListCredentialsComponent
    }))
    .directive(
      'easMFAs',
      downgradeComponent(
        { component: ListMfasComponent }
      )
    )
    .config(config);
})();
