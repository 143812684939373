import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { CredentialTypes } from "@fry/system/account/credential/credential";
import { AuthService } from "@fry/core/auth.service";


@Component({
    selector: 'app-edit-username-credential-dialog',
    templateUrl: './update-credential-username-dialog.component.html',
    imports: [
        CommonModule,
        MatDialogContent,
        MatFormField,
        MatDialogActions,
        ReactiveFormsModule,
        MatInput,
        MatLabel,
        MatError,
        MatDialogTitle
    ]
})
export class UpdateCredentialUsernameDialogComponent implements OnInit {
  editUsernameForm: FormGroup;
  currentCredentialUsername: string;
  dialogTitle: string;
  label: string;

  integrations = { org_fry: { autoGenerateUsername: true, autoGeneratePassword: false } };
  orgHasintegration = false;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpdateCredentialUsernameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentCredentialUsername: string, credentialType: CredentialTypes }
  ) {
    this.currentCredentialUsername = data.currentCredentialUsername;
    if (data.credentialType === CredentialTypes.LOCAL) {
      this.dialogTitle = 'Edit local credential\'s username';
      this.label = 'New Local Username';
    } else if (data.credentialType === CredentialTypes.PROXY) {
      this.dialogTitle = 'Edit SSO credential\'s ID';
      this.label = 'New SSO ID';

      this.orgHasintegration = !!this.integrations[this.authService.currentOrganisation()];
    }
  }

  ngOnInit(): void {
    this.editUsernameForm = this.fb.group({
      useIntegration: [],
      newUsername: [this.currentCredentialUsername, Validators.required]
    });
  }

  onSubmit() {
    if (this.editUsernameForm.valid) {
      this.dialogRef.close(this.editUsernameForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
