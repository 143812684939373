import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { Response } from "./api";
import { NgTemplateOutlet } from "@angular/common";

@Component({
    selector: "eas-response-wrapper",
    templateUrl: './response.wrapper.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgTemplateOutlet,
    ]
})
export class ResponseWrapperComponent {

    response = input.required<Response<any>>();

}