import { MFAMethod, MFAMethodsEnum, orderedMFAMethods } from "@fry/system/account/mfa/mfa";
import { CredentialTypes } from "@fry/system/account/credential/credential";


export class Organisation {
    id: string;
    name: string;
    shortName: string;

    enabledLabs: boolean;
    allowedAccountTypes: CredentialTypes[];
    enabledMFAs: MFAMethod[];

    constructor(doc) {
        this.id = doc.id
        this.name = doc.name;
        this.shortName = doc.shortName;
        this.enabledLabs = doc.enabledLabs;
        this.enabledMFAs = this.convertMFAIdToMFAMethod(doc.enabledMFAs || []);
        this.allowedAccountTypes = doc.allowedAccountTypes;
    }

  private convertMFAIdToMFAMethod(enabledMFAs: MFAMethodsEnum[]): MFAMethod[] {
    return orderedMFAMethods.filter(method => enabledMFAs.includes(method.id));
  }
}
