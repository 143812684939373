import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { passwordStrengthValidator } from "@fry/core/forms/password-strength.validator";
import { PasswordStrengthComponent } from "@fry/core/forms/password-strength.component";


@Component({
    selector: 'app-change-password-credential-dialog',
    templateUrl: './update-credential-password-dialog.component.html',
    imports: [
        CommonModule,
        MatDialogContent,
        MatFormField,
        ReactiveFormsModule,
        MatInput,
        MatLabel,
        MatError,
        MatDialogActions,
        MatDialogTitle,
        PasswordStrengthComponent
    ]
})
export class UpdatePasswordDialogComponent implements OnInit {
  changePasswordForm: FormGroup;
  password: string = '';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpdatePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { username: string }
  ) {
  }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, passwordStrengthValidator()]],
      passwordConfirmation: ['', [Validators.required, this.passwordsShouldMatch('newPassword')]]
    });
  }

  passwordsShouldMatch(matchTo: string) {
    return (control: AbstractControl) => {
      return control?.parent?.controls[matchTo].value === control.value ? null : { mismatch: true };
    };
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      this.dialogRef.close(this.changePasswordForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
