<section class="wrapper">
  <header class="row">
    <h1>Two Factor Authentication</h1>
  </header>

  <div class="row">
    <div class="col-xs-12">
      <p class="box box-warning">
        Your organisation has enforced two factor authentication on your account. You cannot turn it off, but you can add or change your second steps.
      </p>

      <div class="row row-bordered" *ngFor="let mfa of mfas;">
        <div class="col-xs-8">
          <p>{{ readableName[mfa.type] }}</p>
        </div>
        <div class="col-xs-4 text-right">
          <button class="btn btn-sm btn-danger" (click)="deleteMFA(mfa.id)" *ngIf="canDelete()">Remove</button>
        </div>
      </div>

      <div class="add-credential" *ngIf="canAdd()">
        <p><button class="btn btn-primary" (click)="createMFA()">Setup two factor authentication</button></p>
      </div>
    </div>
  </div>
</section>
