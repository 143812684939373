export enum SYSTEM_ROLE_ID {
  ADMIN              = 'system:admin',
  ANONYMOUS_EXTERNAL = 'system:anonymous-external',
  ANYONE             = 'system:anyone',
  PENDING_USER       = 'system:pending-user',
  SUPER_ADMIN        = 'system:superadmin',
  SYSTEM             = 'system:system',
  TIMELINE_OWNER     = 'system:timeline-owner',
}

export enum PERMISSION_ID {
  CREDENTIAL_MANAGE = 'credential.manage',
}
