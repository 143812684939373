<h2 mat-dialog-title>Change Password</h2>
<form [formGroup]="changePasswordForm">
  <mat-dialog-content>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>New Password</mat-label>
      <input matInput type="password" formControlName="newPassword" required autocomplete="new-password" passwordrules="required: upper; required: lower; required: digit; required: [-().&@?'#,/&quot;+]" />
    </mat-form-field>
    <app-password-strength
      [control]="changePasswordForm.get('newPassword')"></app-password-strength>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Re-type New Password</mat-label>
      <input matInput type="password" formControlName="passwordConfirmation" required autocomplete="new-password" />
      <mat-error *ngIf="changePasswordForm.get('passwordConfirmation').hasError('required')">Re-type
        New Password is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <ul class="list-inline list-unstyled">
      <li>
        <button class="btn btn-primary" type="submit" [disabled]="changePasswordForm.invalid"
                (click)="onSubmit()">Change
        </button>
      </li>
      <li>
        <button class="btn btn-warning btn-outline" (click)="onCancel()">Cancel</button>
      </li>
    </ul>
  </mat-dialog-actions>
</form>
