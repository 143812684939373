(function() {
  'use strict';

  function UserViewController($scope, Auth, User, Users, Security, Utils) {
    var ctrl = this;

    ctrl.user = Users.remoteUser || Auth.currentUser();
    ctrl.remoteUser = Users.remoteUser;

    ctrl.userIsDisabled = false;
    ctrl.hasEditPermission = false;
    ctrl.hasManageCredentialPermission = false;
    ctrl.canReview = false;

    Users.find(ctrl.user)
      .then(function(profile) {
        ctrl.userObj = new User(profile);
        ctrl.profile = profile;

        if (Users.remoteUser) {
          var fullName = _.trim(
            (ctrl.profile.firstName || '') + ' ' + (ctrl.profile.lastName || '')
          );
          Utils.setPageTitle(fullName + '\'s profile');
        } else {
          Utils.setPageTitle('My profile');
        }

        ctrl.userIsDisabled = profile.state === 'disabled';
        ctrl.actionButtons = ctrl.userObj.profileActionButtons();
      })
      .catch(Utils.showError)
      .finally(function() {
        ctrl.loaded = true;
      });

    var editPermission = 'users.edit';
    var reviewPermission = 'users.review';
    var manageCredentialPermission = 'credential.manage';

    if (Users.remoteUser) {
      Security.hasPermissionFor(editPermission, ctrl.user)
        .then(function(res) {
          ctrl.hasEditPermission = res;
        });
    } else {
      Security.hasPermission(editPermission + '.own')
        .then(function(res) {
          ctrl.hasEditPermission = res;
        });
    }

    Security.hasPermissionFor(reviewPermission, ctrl.user)
      .then(function() {
        ctrl.canReview = true;
      });

    Security.hasPermissionFor(manageCredentialPermission, ctrl.user)
      .then(function() {
        ctrl.hasManageCredentialPermission = true;
      });

    ctrl.reloadUser = function(doc) {
      ctrl.profile = doc;
      ctrl.userIsDisabled = ctrl.profile.state === 'disabled';
      ctrl.actionButtons = ctrl.userObj.profileActionButtons();
    };

    ctrl.approve = function() {
      return Users.updateState(ctrl.profile, 'user_approve')
        .then(function() {
          return Users.find(ctrl.profile.username, { noCache: true });
        })
        .then(function(doc) {
          ctrl.reloadUser(doc);
        })
        .catch(Utils.showError);
    };

    $scope.$on('KZUserReloaded', function(_evt, args) {
      if (args && args.doc.user === ctrl.profile.user) {
        ctrl.reloadUser(args.doc);
      }
    });
  }

  UserViewController.$inject = [
    '$scope',
    'AuthService',
    'UserFactory',
    'UsersService',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.users')
    .controller('UserViewController', UserViewController);
})();
