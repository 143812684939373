import { ChangeDetectionStrategy, Component, inject, signal } from "@angular/core";
import { FeatureSettingsComponent } from "./feature.settings.component";
import { FeaturesService } from "./features.service";
import { SecurityService } from "@fry/core/security.service";
import { ResponseWrapperComponent } from "@fry/core/response.wrapper";

@Component({
    selector: "eas-organisation-settings",
    templateUrl: './organisation.settings.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FeatureSettingsComponent,
        ResponseWrapperComponent,
    ]
})
export class OrganisationSettingsComponent {

  private security = inject(SecurityService);
  private featureService = inject(FeaturesService);
  public featureResponse = this.featureService.featureResponse;
  public features = this.featureService.freeFeatures;
  public canManageFeatures = signal<boolean>(false);

  async ngOnInit() {
    this.security.hasPermission('features.manage').then(hasRole => this.canManageFeatures.set(hasRole));
    await this.featureService.fetchFeatures();
  }

}