import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgClass } from "@angular/common";

@Component({
    selector: 'app-password-strength',
    templateUrl: './password-strength.component.html',
    styleUrls: ['./password-strength.component.css'],
    imports: [
        NgClass
    ]
})
export class PasswordStrengthComponent {
  @Input() control!: AbstractControl;

  get strength(): string {
    if (!this.control || !this.control.value) return 'Empty';

    const password = this.control.value;
    const validCriteriaCount = this.getValidCriteriaCount(password);

    if (validCriteriaCount === 5) return 'Strong';
    if (validCriteriaCount >= 3) return 'Moderate';
    if (validCriteriaCount >= 1) return 'Weak';
    return 'Empty';
  }

  get criteria() {
    const password = this.control?.value || '';
    return {
      hasMinLength: password.length >= 12,
      hasLowercase: /[a-z]/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[\W_]/.test(password),
    };
  }

  private getValidCriteriaCount(password: string): number {
    const criteria = {
      hasMinLength: password.length >= 12,
      hasLowercase: /[a-z]/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[\W_]/.test(password),
    };
    return Object.values(criteria).filter(Boolean).length;
  }
}
