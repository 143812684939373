 import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { CommonModule, NgSwitch } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogTitle } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { MFAService } from "@fry/system/account/mfa/mfa.service";
import * as QRCode from 'qrcode';
import { MFAMethod, MFAMethodsEnum } from "@fry/system/account/mfa/mfa";
 import { CodeInputModule } from "angular-code-input";


@Component({
    selector: 'app-mfa-setup',
    templateUrl: './create-mfa-dialog.component.html',
    imports: [
        CommonModule,
        NgSwitch,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        FormsModule,
        ReactiveFormsModule,
        CodeInputModule,
        CodeInputModule,
        ReactiveFormsModule
    ]
})
export class CreateMfaDialogComponent {
  @Input() credentialUsername: string = '';
  @Output() completed = new EventEmitter<any>();

  protected readonly MFAMethodsEnum = MFAMethodsEnum;

  availableMethods: MFAMethod[] = [];

  currentStep: 'selectMethod' | 'completeRegistration' = 'selectMethod';
  selectedMethod: MFAMethodsEnum | null = null;

  errorMessage: string = '';
  mfaId: string | null = null;

  // Email OTP variables
  form: FormGroup;
  qrCodeDataUrl: string | null = null;
  secretKey: string | null = null;


  constructor(
    private mfaService: MFAService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { credentialUsername: string, availableMethods: MFAMethod[] }
  ) {
    this.credentialUsername = data.credentialUsername;
    this.availableMethods = data.availableMethods;

    this.form = this.fb.group({
      code: ['', Validators.required],
    });

    this.form = this.fb.group({
      code: ['', Validators.required],
    });
  }

  selectMethod(method: MFAMethodsEnum.TOTP | MFAMethodsEnum.EMAIL_OTP) {
    this.selectedMethod = method;
    this.currentStep = 'completeRegistration';
    this.initRegistration(method);
  }

  onCodeCompleted(code: string): void {
    this.form.patchValue({ code });
  }

  // Initiate Registration
  async initRegistration(method: MFAMethodsEnum.TOTP | MFAMethodsEnum.EMAIL_OTP) {
    let response;
    if (method === MFAMethodsEnum.EMAIL_OTP) {
      response = await this.mfaService.initEmailOTPRegistration(this.credentialUsername);
    } else if (method === MFAMethodsEnum.TOTP) {
      response = await this.mfaService.initTOTPRegistration(this.credentialUsername);
    }

    if (response.state !== 'ok') {
      this.errorMessage = `Failed, reason: ${response.error}.`;
      return;
    }

    this.mfaId = response.data.mfa_id;
    if (method === MFAMethodsEnum.TOTP) {
      this.secretKey = response.data.uri.match(/secret=([^&]+)/)[1];
      this.qrCodeDataUrl = await QRCode.toDataURL(response.data.uri);
    }
  }

  // Complete Registration (Unified for both TOTP and Email OTP)
  async completeRegistration() {
    const code = this.selectedMethod === MFAMethodsEnum.EMAIL_OTP ? this.form.value.code : this.form.value.code;

    let response;
    if (this.selectedMethod === MFAMethodsEnum.EMAIL_OTP) {
      response = await this.mfaService.completeEmailOTPRegistration(this.credentialUsername, this.mfaId!, code);
    } else if (this.selectedMethod === MFAMethodsEnum.TOTP) {
      response = await this.mfaService.completeTOTPRegistration(this.credentialUsername, this.mfaId!, code);
    }

    if (response?.state === 'ok') {
      this.completed.emit({ method: this.selectedMethod, status: 'success' });
    } else {
      this.errorMessage = `Failed: ${response.error}`;
    }
  }

  // Determine if the Complete Registration button should be disabled
  isCompleteRegistrationDisabled(): boolean {
    return this.selectedMethod === MFAMethodsEnum.EMAIL_OTP
      ? !this.form.valid
      : this.selectedMethod === MFAMethodsEnum.TOTP
      ? !this.form.valid
      : true;
  }

  // Utility function for copying TOTP secret key
  async copyToClipboard() {
    if (this.secretKey) {
      try {
        await navigator.clipboard.writeText(this.secretKey);
      } catch (err) {
        console.error('Failed to copy to clipboard:', err);
      }
    }
  }

  // Go back to the previous step
  goBack() {
    this.currentStep = 'selectMethod';
    this.selectedMethod = null;
    this.errorMessage = '';
    this.qrCodeDataUrl = null;
    this.secretKey = null;
    this.form.reset();
    this.form.reset();
  }

  closeDialog() {
    this.completed.emit({ method: null, status: 'cancelled' });
  }

}
