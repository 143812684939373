import { CommonModule, NgIf } from "@angular/common";
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption, MatSelect } from "@angular/material/select";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";
import { passwordStrengthValidator } from "@fry/core/forms/password-strength.validator";
import { PasswordStrengthComponent } from "@fry/core/forms/password-strength.component";


@Component({
    selector: 'app-local-credential-setup-dialog',
    templateUrl: './create-local-credential-dialog.component.html',
    imports: [
        CommonModule,
        MatDialogContent,
        MatDialogTitle,
        MatFormField,
        MatSelect,
        MatOption,
        MatDialogActions,
        ReactiveFormsModule,
        MatInput,
        MatButton,
        MatLabel,
        MatError,
        PasswordStrengthComponent,
        FormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        NgIf,
        PasswordStrengthComponent,
        ReactiveFormsModule,
        NgIf
    ]
})
export class CreateLocalCredentialDialogComponent implements OnInit {
  localForm: FormGroup;
  isForRemoteUser: boolean;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreateLocalCredentialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isForRemoteUser: boolean }
  ) {
    this.isForRemoteUser = data.isForRemoteUser;
  }

  ngOnInit(): void {
    this.localForm = this.fb.group({
      username: ['', Validators.required],
      passwordOptions: [],
      password: ['']
    });

    // Show password field only if "isForRemoteUser" is false or "passwordOptions" is "customPassword"
    this.updatePasswordFieldVisibility();

    // Update password field visibility when passwordOptions changes
    this.localForm.get('passwordOptions').valueChanges.subscribe(() => {
      this.updatePasswordFieldVisibility();
    });
  }

  updatePasswordFieldVisibility() {
    const passwordControl = this.localForm.get('password');
    const passwordOptions = this.localForm.get('passwordOptions').value;
    const validators = [Validators.required, passwordStrengthValidator()];

    if (this.isForRemoteUser && passwordOptions !== 'customPassword') {
      passwordControl.clearValidators();
    } else {
      passwordControl.setValidators(validators);
    }

    passwordControl.updateValueAndValidity();
  }

  onSubmit() {
    if (this.localForm.valid) {
      this.dialogRef.close(this.localForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
