<section class="wrapper">
  <header class="row">
    <h1>Account security</h1>
  </header>

  <div class="row">
    <div class="col-xs-12">
      <mat-tab-group>
        <mat-tab label="Login credentials">
          <p>This is how you currently login to risr/advance. Make sure you can always access your
            account by keeping this information up to date.</p>

          <div class="row row-bordered" *ngFor="let loginCredential of loginCredentials;">
            <div class="col-xs-4">
              <div class="label label-info">{{ readableName[loginCredential.type] }}</div>
              <div><strong>{{ loginCredential.username }}</strong></div>
              <div class="label label-warning" *ngIf="loginCredential.passwordNotSet">
                A welcome email will be sent to guide the user through the setup process
              </div>
            </div>
            <div class="col-xs-8 text-right">
              <ul class="list-inline list-unstyled">
                <li *ngIf="canAccessTwoFactorAuth(loginCredential.type)">
                  @if (isForRemoteUser()) {
                    <a class="btn btn-sm btn-success" routerLinkJs="epf.users.mfas"
                       [routerParamsJs]="{credentialUsername: loginCredential.username, remoteUser: remoteUser()}">Two
                      factor authentication</a>
                  } @else {
                    <a class="btn btn-sm btn-success" routerLinkJs="dashboard.profile.mfas"
                       [routerParamsJs]="{credentialUsername: loginCredential.username}">Two factor
                      authentication</a>
                  }
                </li>
                <li *ngIf="canUpdateUsername(loginCredential.type)">
                  <button class="btn btn-sm btn-success" (click)="updateUsername(loginCredential)">
                    Edit username
                  </button>
                </li>
                <li *ngIf="canUpdateSSOID(loginCredential.type)">
                  <button class="btn btn-sm btn-success" (click)="updateUsername(loginCredential)">
                    Edit SSO ID
                  </button>
                </li>
                <li *ngIf="canSendPasswordEmail(loginCredential.type)">
                  <button class="btn btn-sm btn-success"
                          (click)="sendPasswordEmail(loginCredential)">Send password reset email
                  </button>
                </li>
                <li *ngIf="canUpdatePassword(loginCredential.type)">
                  <button class="btn btn-sm btn-success" (click)="updatePassword(loginCredential)">
                    Change password
                  </button>
                </li>
                <li *ngIf="canDelete()">
                  <button class="btn btn-sm btn-danger" (click)="deleteCredential(loginCredential)">
                    Remove
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <ul class="mt-4 list-inline list-unstyled">
            <li *ngFor="let type of allowedAccountTypes">
              <button class="btn btn-primary" (click)="createCredential(type)"
                      *ngIf="canCreateCredential(type)">Add {{ readableName[type] }} Credential
              </button>
            </li>
          </ul>
        </mat-tab>

        <mat-tab label="API keys" *ngIf="canAccessAPIKeys()">
          <div *ngIf="newlyCreatedAPICredential" class="box box-warning">
            <p>
              <i class="icon-warning"></i><strong>Warning!</strong> Make sure you copy the API
              credentials below. You won't be able to see them again!
            </p>
          </div>

          <div *ngIf="newlyCreatedAPICredential" class="box box-info row">
            <div class="col-xs-8">
              <ul>
                <li>public_key: {{ newlyCreatedAPICredential.public_key }}</li>
                <li>secret_key: {{ newlyCreatedAPICredential.secret_key }}</li>
              </ul>
            </div>
            <div class="col-xs-4 text-right">
              <button class="btn btn-info copy-btn" (click)="copyToClipboard()">Copy</button>
            </div>
          </div>
          <div class="row row-bordered" *ngFor="let apiCredential of apiCredentials;">
            <div class="col-xs-8">
              <div class="label label-info">{{ readableName[apiCredential.type] }}</div>
              <div><strong>{{ apiCredential.public_key }}</strong></div>
            </div>
            <div class="col-xs-4 text-right">
              <button class="btn btn-sm btn-danger" (click)="deleteCredential(apiCredential)">
                Remove
              </button>
            </div>
          </div>

          <div class="add-credential">
            <p>
              <button class="btn btn-primary" (click)="createApiCredential()"
                      *ngIf="canCreateCredential(CredentialTypes.API)">Add API credential
              </button>
            </p>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>
