import { ChangeDetectionStrategy, Component, computed, inject,} from "@angular/core";
import { FeatureSettingsComponent } from "./feature.settings.component";
import { FeaturesService } from "./features.service";
import { ResponseWrapperComponent } from "@fry/core/response.wrapper";

@Component({
    selector: "eas-labs-settings",
    templateUrl: './labs.settings.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FeatureSettingsComponent,
        ResponseWrapperComponent,
    ]
})
export class LabsSettingsComponent {

  private featureService = inject(FeaturesService);
  private features = this.featureService.labsFeatures;

  spotlightFeatures = computed(() => this.features().filter(f => f.spotlight));
  normalFeatures = computed(() => this.features().filter(f => !f.spotlight));

  public featureResponse = this.featureService.featureResponse;

  async ngOnInit() {
    await this.featureService.fetchFeatures();
  }

}