import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle,
  MatDialogRef
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";


@Component({
    selector: 'app-proxy-credential-setup-dialog',
    templateUrl: './create-proxy-credential-dialog.component.html',
    imports: [
        CommonModule,
        MatDialogContent,
        MatDialogTitle,
        MatFormField,
        MatDialogActions,
        ReactiveFormsModule,
        MatInput,
        MatButton,
        MatLabel,
        MatError
    ]
})
export class CreateProxyCredentialDialogComponent implements OnInit {
  proxyForm: FormGroup;
  isForRemoteUser: boolean;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreateProxyCredentialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isForRemoteUser: boolean }
  ) {
    this.isForRemoteUser = data.isForRemoteUser;
  }

  ngOnInit(): void {
    this.proxyForm = this.fb.group({
      username: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.proxyForm.valid) {
      this.dialogRef.close(this.proxyForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
