import { Injectable, computed, inject, signal } from '@angular/core';
import { AccessTokenCallback, User, UserLoadedCallback, UserManager } from 'oidc-client-ts';
import { BroadcastService } from './broadcast.service';
import { NetworkService } from './network.service';
import { MatDialog } from '@angular/material/dialog';
import { StepupComponent } from './stepup.component';
import { SigninPopupComponent } from './signin-popup.component';

export interface OidcOptions {
    scope?: string;
    resource?: string;
    organisation?: string;
}

export interface EasUser {
    username: string;
    usernameHeader: string;
    login_type: 'oidc';
    masqueradeBy?: string;
    originalProfile: {
        username: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        fullName?: string;
    },
    user: User;

}

export interface ApiInfo {
    token: string;
    organisation: string;
    username: string;
}

@Injectable({
    providedIn: 'root'
})
export class OidcService {

    private dialog = inject(MatDialog);

    readonly $currentOrganisation = signal<string | null>(null);
    readonly currentUserSignal = signal<User>(null);
    readonly currentUser = computed(() => this.userToEasUser(this.currentUserSignal()));
    readonly apiInfo = computed(() => this.getApiInfo(this.currentUser()));

    private _organisation: string;

    private _renewTokenPromise: Promise<User> | null = null;
    private userLoadedCallback: UserLoadedCallback = (user: User) => {
        if (user) {
            console.log('OIDC: User loaded', user.expires_in, user.scopes);
            this.currentUserSignal.set(user);
        } else {
            console.log('OIDC: User loaded - no user');
        }
    }
    private accessTokenExpiredCallback: AccessTokenCallback = () => {
        console.log('OIDC: Token expired');
    }
    private accessTokenExpiringCallback: AccessTokenCallback = () => {
        console.log('OIDC: Token expiring');
        this.renewToken();
    }

    private callbacks = {
        UserLoaded: this.userLoadedCallback,
        AccessTokenExpired: this.accessTokenExpiredCallback,
        AccessTokenExpiring: this.accessTokenExpiringCallback,
    }

    userManager: UserManager;
    private broadcast = inject(BroadcastService);
    private network = inject(NetworkService);

    constructor() {
        const org = localStorage.getItem('currentOrganisation');
        this.setOrganisation(org);
    }

    public async init(initOptions: {relogin: boolean}): Promise<User | null> {
        console.log('OIDC: init');
        this.userManager = this.createManager();
        console.log('OIDC: created');

        Object.entries(this.callbacks).forEach(([name, callback]) => {
            this.userManager.events[`add${name}`](callback);
        });

        try {
            const user = await this.userManager.getUser();
            if (!user) {
                // return user;
                if (initOptions.relogin) {
                    this.login();
                    throw new Error('User not logged in');
                }
                return null;
            } else if (user.expired) {
                return this.renewToken({ silent: true }).catch(err => {
                    console.log(err);
                    if (initOptions.relogin) {
                        this.login();
                        throw new Error('User not logged in');
                    }
                    return null;
                });
            } else {
                const userToStore = { ...user, access_token: null, refresh_token: null };
                localStorage.setItem('oidcUser', JSON.stringify(userToStore));
                this.currentUserSignal.set(user);
                return user;
            }
        } catch (err) {
            if (this.network.isOffline()) {
                console.log('OIDC: Offline');
                const userJSON = localStorage.getItem('oidcUser');
                if (userJSON) {
                    const user = JSON.parse(userJSON);
                    this.currentUserSignal.set(user);
                    return user;
                }
                return null;
            }
            console.log("OIDC: init error", err);
            return null;
        };
    }

    public async resetManager(): Promise<User | null> {
        if (this.userManager) {
            Object.entries(this.callbacks).forEach(([name, callback]) => {
                this.userManager.events[`remove${name}`](callback);
            });
        }
        return this.userManager.removeUser().then(() => {
            this.userManager = null;
            return this.init({ relogin: true });
        });
    }

    private getLoginSettings(options: {username?: string, scope?: string}): OidcOptions {
        const scopes = ['openid', 'profile', 'email'];
        let masquerade: string;
        if (options.username === null) {
            localStorage.removeItem('eas:lastMasquerade');
            masquerade = null;
        } else if (options.username) {
            localStorage.setItem('eas:lastMasquerade', options.username);
            masquerade = options.username;
        } else {
            masquerade = localStorage.getItem('eas:lastMasquerade');
        }

        let resource: string;
        if (masquerade) {
            scopes.push('lase');
            scopes.push(`lase:${masquerade}`);
            resource = `https://risr.global/resources/api+lase:${masquerade}`;
        } else if (options.scope) {
            scopes.push(options.scope);
            resource = `https://risr.global/resources/api+${options.scope}`;
        } else {
            resource = 'https://risr.global/resources/api';
        }

        const oidcOptions: OidcOptions = {
            scope: scopes.join(' '),
            resource,
        };
        console.log('OIDC: getLoginSettings', oidcOptions);
        return oidcOptions;
    }

    createManager(): UserManager {
        var serverSettings = window['kzConfig'] ? window['kzConfig'].oidc : {};

        var defaultSettings = {
            automaticSilentRenew: false,
            revokeAccessTokenOnSignout: true,
            accessTokenExpiringNotificationTime: 60,
            loadUserInfo: false
        };

        const settings = {
            ...defaultSettings,
            ...serverSettings,
            scope: ''
        };

        if (this.organisation) {
            settings.extraQueryParams = { kz_org: this.organisation };
        }

        console.log('OIDC', settings);
        return new UserManager(settings);
    }

    private userToEasUser(user: User): EasUser {
        console.log('OIDC: userToEasUser');
        if (!user) {
            return null;
        }

        const laseMatch = user.scope.match(/lase:([a-zA-Z0-9\-_]+)/);
        let username = user.profile.sub;
        let usernameHeader = user.profile.sub;
        let masqueradeBy;
        if (laseMatch) {
            username = laseMatch[1];
            usernameHeader = `${username}:lase:${user.profile.sub}`
            masqueradeBy = user.profile.sub;
        }
        return {
            username: username,
            login_type: 'oidc',
            usernameHeader: usernameHeader,
            masqueradeBy: masqueradeBy,
            originalProfile: {
                username: user.profile.sub,
                firstName: (user.profile.firstName || '') as string,
                lastName: (user.profile.lastName || '') as string,
                email: user.profile.email,
                fullName: ((user.profile.firstName || '') +
                ' ' + (user.profile.lastName || '')).trim() || user.profile.email
            },
            user: user,
        };
    }


    public async easUser(): Promise<EasUser | null> {
        const easUser = this.currentUser();
        if (easUser && easUser.user && !easUser.user.expired) {
            return easUser;
        }

        return this.user().then((user: User) => {
            return this.userToEasUser(user);
        });
    }

    public async user(): Promise<User | null> {
        console.log("OIDC: Requesting user")
        return this.userManager.getUser().then((user: User) => {
            if (user && !user.expired) {
                return user;
            } else if (user) {
                console.log('OIDC: Renew when getting user');
                return this.renewToken({ silent: true }).then((user: User) => {
                    return user;
                }).catch(err => {
                    console.log(err);
                    return null;
                });
            } else {
                return null;
            }
        });
    }

    public login(): Promise<void> {
        const options = this.getLoginSettings({});
        var href = location.pathname + location.search + location.hash;
        sessionStorage.setItem('lastUrl', href);
        return this.userManager.signinRedirect(options);
    }

    public loginPopup(): Promise<User> {
        const options = this.getLoginSettings({});
        return this.userManager.signinPopup(options);
    }

    public async renewToken(opts?: { silent: boolean }): Promise<User> {
        const loginOptions = opts || { silent: false};

        const options = this.getLoginSettings({});
        console.log("OIDC: Requesting renewToken");
        if (this._renewTokenPromise === null) {
            console.log("OIDC: renewToken");
            this._renewTokenPromise = this.userManager.signinSilent(options).then(user => {
                console.log("OIDC: renewToken ok");
                this._renewTokenPromise = null;
                return user;
            }).catch(err => {
                if (loginOptions.silent) {
                    return Promise.reject(err);
                }
                console.log('OIDC: renewToken err - trying popup', err);
                return this.signInPopup(options).then(user => {
                    console.log("OIDC: renewToken via popup ok");
                    this._renewTokenPromise = null;
                    return user;
                });
            }).catch(err => {
                console.log("OIDC: renewToken err", err);
                this._renewTokenPromise = null;
                throw err;
            });
        }
        return this._renewTokenPromise;
    }

    private async signInPopup(options: OidcOptions): Promise<User> {
        const res = await this.dialog.open(SigninPopupComponent).afterClosed().toPromise();
        console.log("OIDC: stepUpPopup", res);
        if (res) {
            return await this.userManager.signinPopup(options);
        }
        return Promise.reject({ status: 401, message: 'Sign-in required', code: 'sign-in' });
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }

    public loginAs(username: string): Promise<void> {
        const options = this.getLoginSettings({username});
        return this.userManager.signinRedirect(options);
    }

    public async stepUp(): Promise<void> {
        const options = this.getLoginSettings({ scope: 'stepup' });
        try {
            const user = await this.userManager.signinSilent(options);
            if (!user || !user.scope.includes('stepup')) {
                throw new Error('Step up authentication required');
            }
        } catch (err) {
            console.log("OIDC: stepUp err - trying popup", err);
            await this.stepUpPopup(options);
        }
    }

    private async stepUpPopup(options: OidcOptions): Promise<void> {
        const res = await this.dialog.open(StepupComponent).afterClosed().toPromise();
        console.log("OIDC: stepUpPopup", res);
        if (res) {
            await this.userManager.signinPopup(options);
        }
    }

    public async ensureStepup(): Promise<void> {
        let user = this.currentUserSignal();
        if (user && user.scope.includes('stepup')) {
            return Promise.resolve();
        }

        await this.stepUp();

        user = this.currentUserSignal();
        if (user && user.scope.includes('stepup')) {
            return Promise.resolve();
        }

        return Promise.reject({ status: 401, message: 'Step up authentication required', code: 'step-up' });
    }

    public logoutAs() {
        return this.loginAs(null);
    };

    public async signinCallback(): Promise<User> {
        const manager = this.createManager();
        return await manager.signinRedirectCallback();
    }

    public async signinPopupCallback(): Promise<void> {
        const manager = this.createManager();
        return await manager.signinPopupCallback();
    }

    public async signinSilentCallback(): Promise<void> {
        const manager = this.createManager();
        return await manager.signinSilentCallback();
    }

    public async isLoggedIn(): Promise<boolean> {
        if (this.network.isOffline()) {
            return this.currentUserSignal() !== null;
        }

        return this.userManager.getUser().then(user => {
            return user !== null && !user.expired;
        });
    }

    public get organisation(): string {
        if (this._organisation === undefined) {
            let org;
            try {
                org = localStorage.getItem('currentOrganisation');
            } catch (err) {
                console.log('Could not get organisation from local storage', err);
                org = null;
            }

            this._organisation = org;
        }

        return this._organisation;
    }

    public setOrganisation(organisation: string | null): void {
        // FIXME - update Sentry
        this._organisation = organisation;
        try {
            if (organisation) {
                localStorage.setItem('currentOrganisation', organisation);
            } else {
                localStorage.removeItem('currentOrganisation');
            }
        } catch (err) {
            console.log(err);
        }
        this.$currentOrganisation.set(organisation);
        this.broadcast.broadcast('OrganisationSettingsChanged');
        this.broadcast.broadcast('KZClearCache');
    }

    public unsetOrganisation(): void {
        this.setOrganisation(null);
    }

    private getApiInfo(easUser: EasUser): ApiInfo {
        return {
            token: `Bearer ${easUser.user.access_token}`,
            organisation: this.$currentOrganisation(),
            username: easUser.usernameHeader,
        };
    }

    public ensureLoggedIn(): Promise<void> {
        return this.easUser().then((user: EasUser) => {
            if (user === null) {
                throw new Error('User not logged in');
            }
        });
    }

    public getApiHeaders(): Promise<ApiInfo> {
        return this.easUser().then((easUser: EasUser) => {
            if (easUser) {
                return this.getApiInfo(easUser);
            }
            throw new Error('User not logged in');
        });
    }
}