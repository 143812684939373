<div class="password-strength">
  <span>Password strength: {{ strength }}</span>
  <div class="strength-meter">
    <div
      class="strength-bar"
      [ngClass]="{
        weak: strength === 'Weak',
        moderate: strength === 'Moderate',
        strong: strength === 'Strong'
      }"
    ></div>
  </div>
  <ul class="password-requirements">
    <li [ngClass]="{ valid: criteria.hasMinLength }">At least 12 characters</li>
    <li [ngClass]="{ valid: criteria.hasLowercase }">At least one lowercase letter</li>
    <li [ngClass]="{ valid: criteria.hasUppercase }">At least one uppercase letter</li>
    <li [ngClass]="{ valid: criteria.hasNumber }">At least one number</li>
    <li [ngClass]="{ valid: criteria.hasSpecialChar }">At least one special character</li>
  </ul>
</div>
