import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value || '';
    const errors: { [key: string]: boolean } = {};

    // Validation criteria
    if (password.length < 12) errors.minLength = true;
    if (!/[a-z]/.test(password)) errors.lowercase = true;
    if (!/[A-Z]/.test(password)) errors.uppercase = true;
    if (!/\d/.test(password)) errors.number = true;
    if (!/[\W_]/.test(password)) errors.specialChar = true;

    // Return errors if any validation criteria are not met
    return Object.keys(errors).length ? errors : null;
  };
}
